import { a } from "gatsby";
import React from "react";

const Share = ( props ) => {
  const { current_page, colour="pink" } = props
  const theme = {
    "pink":{
      "icon": "h-8 fill-current text-abx-pink hover:text-abx-blue",
      "text": "text-abx-pink font-semibold mr-3"
  },
    "white":{
      "icon":"h-8 fill-current text-white hover:text-abx-pink",
      "text": "text-white font-semibold mr-3"
    }
  }
  return (
    <div className="flex items-baseline">
    <div className={theme[colour].text}>
            Share to
          </div>
      <div className="mt-5 flex items-center space-x-3 relative top-2 ">
            {/* Facebook */}
          <div>
            <a href="https://www.facebook.com/alchemybox/">
            <svg
              className={theme[colour].icon}
              viewBox="0 0 58 58"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M21.75 19.3333H14.5V29H21.75V58H33.8333V29H42.6348L43.5 19.3333H33.8333V15.3048C33.8333 12.9968 34.2973 12.0833 36.5279 12.0833H43.5V0H34.2973C25.607 0 21.75 3.82558 21.75 11.1529V19.3333Z" />
            </svg>
            </a>
          </div>
          {/* LinkedIn */}
          <div>
              <a href={"https://www.linkedin.com/shareArticle?mini=true&url="+current_page}>
              <svg
                  className={theme[colour].icon}
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 24 24">
                      <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
            </a>
          </div>
          {/* Twitter */}
          <div>
            <a href={"http://www.twitter.com/share?url="+current_page}>
            <svg
              className={theme[colour].icon + " ml-1"}
              viewBox="0 0 63 63"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M63 11.9625C60.6821 12.9915 58.191 13.6845 55.5765 13.9969C58.2461 12.3982 60.2962 9.86512 61.2596 6.84637C58.7632 8.32687 55.9965 9.40312 53.0512 9.98324C50.6966 7.47112 47.334 5.90137 43.617 5.90137C35.2721 5.90137 29.1401 13.6871 31.0249 21.7695C20.286 21.2314 10.7625 16.0864 4.38637 8.26649C1.00013 14.0756 2.63025 21.675 8.38425 25.5232C6.2685 25.455 4.2735 24.8749 2.53312 23.9062C2.39137 29.8939 6.68325 35.4956 12.8993 36.7425C11.0801 37.236 9.08775 37.3515 7.06125 36.963C8.7045 42.0975 13.4768 45.8329 19.1362 45.9379C13.7025 50.1982 6.8565 52.1014 0 51.2929C5.71987 54.96 12.516 57.0994 19.8135 57.0994C43.8112 57.0994 57.3694 36.8317 56.5504 18.6536C59.0756 16.8292 61.2675 14.5534 63 11.9625Z" />
            </svg>
            </a>
          </div>
    </div>
    </div>
  );
};

export default Share;


