import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import Share from "../components/share";
import { Link } from "gatsby";
import CFOCloud from "../images/cloud-cfo.gif";
import Clouds from "../images/bg-clouds-animation.gif";

const Header = (props) => {
  const {
    title,
    colour,
    logoText,
    subtitle,
    buttons = [],
    margin_bottom = 40,
    clouds = false,
    image = "",
    share = false,
  } = props;
  const theme = {
    pink: {
      background: "flex bg-abx-pink w-full",
      foreground:
        "flex flex-col bg-abx-dark-grey w-full sm:w-3/4 max-w-4xl mx-10 sm:ml-20 relative top-16 mt-2 sm:mt-20 p-12",
      button:
        "bg-abx-pink hover:bg-abx-orange p-3 rounded text-white border-2 border-abx-pink hover:border-abx-orange",
    },
    orange: {
      background: "flex bg-abx-orange w-full",
      foreground:
        "flex flex-col bg-abx-dark-grey w-full sm:w-3/4 max-w-3xl mx-10 sm:ml-20 relative top-16 mt-2 sm:mt-20 p-12",
      button:
        "bg-abx-orange hover:bg-abx-pink p-3 rounded text-white border-2 border-abx-orange hover:border-abx-pink",
    },
    blue: {
      background: "flex bg-abx-light-blue w-full",
      foreground:
        "flex flex-col bg-abx-dark-blue w-full sm:w-3/4 max-w-2xl sm:mx-10 sm:ml-20 relative top-16 mt-2 sm:mt-20 p-8 sm:p-12 mr-8",
      button:
        "bg-abx-light-blue hover:bg-abx-pink p-3 rounded text-abx-dark-blue hover:text-white border-2 border-abx-light-blue hover:border-abx-pink",
    },
    outline: {
      button:
        "bg-transparent hover:bg-abx-pink p-3 rounded text-white border-2 border-white hover:border-transparent",
    },
  };
  const Subtext = () =>
    subtitle != null ? (
      <div className="text-sm lg:text-lg font-light lg:pt-6 lg:pr-6 h-full text-white my-6 leading-relaxed">
        {subtitle}
      </div>
    ) : (
      <div></div>
    );
  /*<div className="lg:block h-full relative right-24 top-36 w-128">      
    <StaticImage className="h-12" src="../images/bg-clouds-animation.gif" alt="Alchemy Box Logo" />
</div>*/

  return (
    <div>
      <div
        className={
          "items-center justify-center align-middle mb-" +
          margin_bottom +
          " font-poppins z-10 mx-0 sm:mx-5"
        }
      >
        <div className={`${theme[colour].background}`}>
          <div className={`${theme[colour].foreground} m-0 sm:m-12`}>
            <div className="flex flex-rows">
              <div className="flex flex-col">
                <div className="flex items-top">
                  <div className="relative right-5 bottom-5">
                    {/*<Link to="/">
                      <div className="w-20">
                        <StaticImage
                          className="object-contain h-18 scale-90"
                          src="../images/ABx Logo.png"
                          alt="Alchemy Box Logo"
                        />
                      </div>
    </Link>*/}
                  </div>
                  <div>
                    <span className="text-white font-normal text-lg relative right-3 bottom-6">
                      {logoText}
                    </span>
                  </div>
                </div>
                <div className="justify-center h-full pb-6">
                  <span className="text-white leading-normal font-semibold text-4xl lg:text-6xl mt-12">
                    {title}
                  </span>
                  <Subtext />
                </div>
                <div className="flex flex-row space-x-3">
                  {buttons.map((btn, idx) => (
                    <Link to={btn.link} key={idx}>
                      <button className={theme[btn.colour].button}>
                        {btn.text}
                      </button>
                    </Link>
                  ))}
                </div>
              </div>
              <div className="hidden lg:block">
                <div>
                  <GatsbyImage
                    image={image.gatsbyImageData}
                    className={"h-72 w-72 relative left-48"}
                    alt={image.title}
                  />
                </div>
                <div className={share ? "relative left-20" : "hidden"}>
                  <Share colour="white" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-none justify-end">
            <div className="flex flex-col justify-end">
              {clouds ? (
                <img
                  className="hidden lg:block h-full relative top-32 left-24"
                  src={Clouds}
                  alt="Alchemy Box Logo"
                />
              ) : (
                <div></div>
              )}
              {clouds ? (
                <img
                  className="hidden lg:block h-full relative top-16 right-16"
                  src={CFOCloud}
                  alt="Alchemy Box Logo"
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
