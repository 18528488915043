import React, { useState } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Logo from "../images/ABx Logo Blue.png";

const Nav = (props) => {
  const { colour, default_state = "" } = props;
  const [selected, setSelected] = useState(default_state);
  const [opened, setOpened] = useState(false);

  const theme = {
    grey: {
      background: "fixed bg-abx-light-grey w-full z-20 opacity-97 px-10",
      mobile: "sm:hidden flex items-center p-3 bg-abx-light-grey",
    },
    white: {
      background: "fixed bg-white w-full z-20 opacity-97 px-10",
      mobile: "sm:hidden flex items-center p-3 bg-white", //fixed w-full z-20 opacity-97 px-10"
    },
  };

  return (
    <div>
      <div className={`${theme[colour].background} flex flex-row`}>
        <div className="hidden sm:flex w-20 my-2 flex-none">
          <Link to="/">
            <img
              className="object-contain h-18 scale-90"
              src={Logo}
              alt="Alchemy Box Logo"
            />
          </Link>
        </div>
        <div className="hidden sm:flex items-center mx-4 my-3 font-poppins">
          <div className="flex justify-start w-full space-x-5 items-center z-20">
            <AnchorLink to="/#features" title="Product">
              <span
                className={selected == "features" ? "text-abx-pink" : "nav-btn"}
                onClick={() => setSelected("features")}
              >
                Product
              </span>
            </AnchorLink>
            <AnchorLink to="/blog" title="Blog" className="hidden">
              <span
                className={selected == "blog" ? "text-abx-pink" : "nav-btn"}
                onClick={() => setSelected("blog")}
              >
                Blog
              </span>
            </AnchorLink>
            <AnchorLink to="/#contact" title="Contact">
              <span
                className={selected == "contact" ? "text-abx-pink" : "nav-btn"}
                onClick={() => setSelected("contact")}
              >
                Contact
              </span>
            </AnchorLink>
          </div>
          <div className="flex-grow" />
          <div className="hidden flex flex-none justify-end w-full space-x-5 items-center">
            <button
              className="bg-abx-orange hover:bg-abx-pink p-2 rounded text-white"
              onClick={() => console.log(selected)}
            >
              Sign Up
            </button>
            <span className="text-abx-dark-blue hover:text-abx-pink">
              Login
            </span>
          </div>
        </div>
      </div>
      <div className={theme[colour].mobile}>
        <div className="justify-end">
          <div className="hidden sm:hidden w-20 my-2 flex-none">
            <Link to="/">
              <img
                className="object-contain h-18 scale-90"
                src={Logo}
                alt="Alchemy Box Logo"
              />
            </Link>
          </div>
          {/*<div className="flex flex-col space-y-1">
            <div className="w-5 bg-abx-dark-grey border-b-2 border-abx-dark-grey rounded-sm"></div>
            <div className="w-5 bg-abx-dark-grey border-b-2 border-abx-dark-grey rounded-sm"></div>
            <div className="w-5 bg-abx-dark-grey border-b-2 border-abx-dark-grey rounded-sm"></div>
  </div>*/}
          <button
            className={opened ? "hidden" : "mx-3 my-2"}
            onClick={() => setOpened(!opened)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-abx-dark-blue"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="#0E5A7B"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
          <button
            className={opened ? "mx-3 my-2" : "hidden"}
            onClick={() => setOpened(!opened)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#0E5A7B"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </button>
          <div
            className={
              opened ? "my-3 animate-menuOpen text-abx-dark-blue" : "hidden"
            }
          >
            <AnchorLink
              to="/#features"
              title="Product"
              className="block py-2 px-4 text-sm text-abx-dark-blue hover:text-abx-pink text-lg"
            >
              Product
            </AnchorLink>
            <AnchorLink
              to="/blog"
              title="Blog"
              className="hidden block py-2 px-4 text-sm text-abx-dark-blue hover:text-abx-pink text-lg"
            >
              Blog
            </AnchorLink>
            <AnchorLink
              to="/#contact"
              title="Contact"
              className="block py-2 px-4 text-sm text-abx-dark-blue hover:text-abx-pink text-lg"
            >
              Contact Us
            </AnchorLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
